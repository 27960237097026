let _timestampInit = (new Date).getTime();

import Vue          from 'vue';
import App          from '@/App.vue';
import VueRouter    from 'vue-router';

import i18n         from '@/i18n';
import vco          from "v-click-outside";
import Vuelidate    from 'vuelidate';
import BootstrapVue from 'bootstrap-vue';
import VueToast from 'vue-toast-notification'; //import 'vue-toast-notification/dist/theme-default.css';
import VueSweetalert2 from 'vue-sweetalert2';
import VueApexCharts  from 'vue-apexcharts';
import VueMask        from 'v-mask';
//import Scrollspy      from 'vue2-scrollspy';
import FlagIcon from 'vue-flag-icon'
import vueCountryRegionSelect from 'vue-country-region-select';
import io             from 'socket.io-client';
import VueSocketIOExt from 'vue-socket.io-extended';

import store        from '@/state/store';
import router       from '@/router/index';
import {gameToGameIcon, parseISO8601String} from '@/methods';
//import SessionLoginState from '@/state/helpers'
import ToggleButton from 'vue-js-toggle-button'

Vue.mixin({
  methods: {
    parseDate: parseISO8601String,
    gameIcon: gameToGameIcon
  },
})

import "@/design/index.scss";
import 'leaflet/dist/leaflet.css';
import {SessionLoginState} from "@/enums";

const socket = io(process.env.VUE_APP_WS_HOST, {
  path: '/connect',
  transports: ['websocket']
});
Vue.use(VueSocketIOExt, socket);

Vue.config.productionTip = false;

Vue.use(ToggleButton) //

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(FlagIcon);
Vue.use(vco);
Vue.use(VueToast, {
  position: 'bottom-left',
  duration: 1500,
  dismissible: true,
  pauseOnHover: true
});
Vue.use(VueSweetalert2);
Vue.use(VueMask);
Vue.use(require('vue-chartist'));
Vue.component('apexchart', VueApexCharts);
Vue.use(vueCountryRegionSelect);

/* *** Filter *** */
var filter = function(text, length, clamp){
  clamp = clamp || '...';
  var node = document.createElement('div');
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};
Vue.filter('truncate', filter);
/* *** ***** *** */

/* *** Prototypes *** */
Number.prototype.pad = function(size) {
  var s = String(this);
  while (s.length < (size || 2)) {s = "0" + s;}
  return s;
}
/* ** *********** *** */


let ws_times = [];
let ws_time;

fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/status', {credentials: 'include'})
.then(response => {
  if(response.ok){
    return response.json();
  } else{
    console.log(`[CRITICAL] Failed to connect core API: (${response.status})${response.statusText}`);
  }
})
.then(data => {
  store.commit('setLoginState', data.state.login);

  if(store.getters.getLoginState() !== SessionLoginState.LOGGED_IN) {
    // Normal case for not logged in user
    location.replace('https://app.cftools.cloud');
    return;
  } else if(!data.user.authenticated || !data.user.logged) {
    // Should the session state be manipulated
    location.replace('https://app.cftools.cloud');
    return;
  }

  store.commit('setAccountId', data.user.cftools_id);

  fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/bootstrap', {credentials: 'include'})
    .then(response => {
      if(response.ok){
        return response.json();
      } else{
        console.log(`[CRITICAL] Failed to connect core API: (${response.status})${response.statusText}`);
        if(response.status === 401 || response.status === 404 || response.status === 403) {
          location.replace('https://app.cftools.cloud');
        }
      }
    })
    .then(data => {
      if(store.getters.getAccountId() !== data.persona.cftools_id) {
        throw new Error('Invalid account state');
      }
      store.commit('setAccountName', data.persona.username);

      store.commit('setRoles', data.persona.roles);

      store.commit('setPersonaName', data.persona.profile.display_name);
      store.commit('setPersonaAvatar', data.persona.profile.avatar);

      store.commit('setUILanguage', data.preferences.language);

      /* Initialize main Vue instance */
      let vue_instance = new Vue({
        router,
        store,
        i18n,
        fallbackLocale: 'en',
        render: h => h(App),
        sockets: {
          connect() {
            console.log(`[WS] UP`);
            setInterval(function () {
              ws_time = (new Date).getTime();
              socket.emit('keepalive');
            }, 5000);
          },
          disconnect() {
            console.log(`[WS] DOWN`);
          },
          ack(data) {
            console.log(`[WS] Connected to ${data.id}(${data.v})@${data.s}`);
          },
          keepalive() {
            var latency = (new Date).getTime() - ws_time;
            ws_times.push(latency);
            ws_times = ws_times.slice(-30);
            var sum = 0;
            for (var i = 0; i < ws_times.length; i++)
              sum += ws_times[i];

            var avg_latency = Math.round(10 * sum / ws_times.length) / 10;
            if (latency > 250.0)
              console.log(`[WS] (Warning) latency: ${latency}ms, avg-latency: ${avg_latency}ms`);
            socket.latency = avg_latency;
          },
          refresh() {
            console.log(`[WS] Refreshing...`);
            socket.emit('refresh');
          },
          update() {
            console.log(`[WS] Updating resources...`);
            this.update();
            console.log(`[WS] Updated`);
            socket.emit('refresh');
          }
        },
        created: function () {
          let loadingTime = (new Date).getTime() - _timestampInit;
          console.log(`Initialized in ${loadingTime}ms`);
        },
        methods: {
          async update() {
            let response = await fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/bootstrap', {credentials: 'include'});
            if(response.ok) {
              let data = await response.json();

              if(store.getters.getAccountId() !== data.persona.cftools_id) {
                location.reload();
                throw new Error('Invalid account state');
              }
              store.commit('setAccountName', data.persona.username);

              store.commit('setPersonaName', data.persona.profile.display_name);
              store.commit('setPersonaAvatar', data.persona.profile.avatar);

              store.commit('setUILanguage', data.preferences.language);

              store.commit('clearIterableResources');
            }
          }
        },
        mounted() {
          this.$socket.$subscribe('user:bootstraped', function() {
            console.log(`[WS] New tab opened`);
          });
        },
        watch:{
          '$route' (to, from) {
            store.commit('setRoute', to);
          }
        },
      });
      vue_instance.$mount('#app');
    }).catch(error => {
    console.log(`[ERROR] "${error}"`);
    //alert('Sorry, we have encountered an issue serving this request. Please refresh the page!');
    Vue.swal({
      icon: 'error',
      text: 'Sorry, we have encountered an issue serving this request (E:1)'
    }).then(function(){
      location.replace('https://app.cftools.cloud');
    });
  });
})
.catch(error => {
  console.log(`[ERROR] "${error}"`);
  location.replace('https://app.cftools.cloud');
});
