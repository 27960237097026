import i18n from '@/i18n';
import Vue from 'vue';
import Vuex from 'vuex';

import modules from '@/state/modules';
import {SessionLoginState} from '@/enums';

Vue.use(Vuex);

const debug = (process.env.NODE_ENV === 'development');
const store = new Vuex.Store({
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
  state: {
    route: null,

    // Session and account
    accountName: null, // Account name
    accountId: null, // CFToolsID
    loginState: SessionLoginState.NOT_LOGGED_IN,

    // Persona
    personaName: null, // Display name
    personaAvatar: null, // Avatar url

    // User interface
    uiLanguage: null, // Language preference
    uiSubscriber: false, // Has ANY subscription, dictates available views etc.

    // Others
    roles: [], // Account roles
    teams: [],
    servers: [],
    memberships: [],
    resources: {}
  },
  getters: {
    getRoute: (state) => () => {
      if(debug) console.log(`[STORE] getRoute() => ${state.route}`);
      return state.route;
    },
    getLoginState: (state) => () => {
      if(debug) console.log(`[STORE] getLoginState() => ${state.loginState}`);
      return state.loginState;
    },
    getAccountName: (state) => () => {
      if(debug) console.log(`[STORE] getAccountName() => ${state.accountName}`);
      return state.accountName;
    },
    getAccountId: (state) => () => {
      if(debug) console.log(`[STORE] getAccountId() => ${state.accountId}`);
      return state.accountId;
    },
    getPersonaName: (state) => () => {
      if(debug) console.log(`[STORE] getPersonaName() => ${state.personaName}`);
      return state.personaName;
    },
    getPersonaAvatar: (state) => () => {
      if(debug) console.log(`[STORE] getPersonaAvatar() => ${state.personaAvatar}`);
      return state.personaAvatar;
    },
    getUILanguage: (state) => () => {
      if(debug) console.log(`[STORE] getUILanguage() => ${state.uiLanguage}`);
      return state.uiLanguage;
    },
    getSubscriberStatus: (state) => () => {
      if(debug) console.log(`[STORE] getSubscriberStatus() => ${state.uiSubscriber}`);
      return state.uiSubscriber;
    },
    getRoles: (state) => () => {
      // Account roles; Different to team based roles
      if(debug) console.log(`[STORE] getRoles() => ${state.roles}`);
      return state.roles;
    },
    hasRole: (state) => (role) => {
      // Account roles; Different to team based roles
      let result = state.roles.includes(role);
      if(debug) console.log(`[STORE] hasRole(${role}) => ${result}`);
      return result;
    },
    getTeams: (state) => () => {
      if(debug) console.log(`[STORE] getTeams() => ${state.teams}`);
      return state.teams;
    },
    getMemberships: (state) => () => {
      if(debug) console.log(`[STORE] getMemberships() => ${state.memberships}`);
      return state.memberships;
    },
    getServers: (state) => () => {
      if(debug) console.log(`[STORE] getServers() => ${state.servers}`);
      return state.servers;
    },
    getServer: (state) => (server_id) => {
      let server = null;
      state.servers.forEach((s) => {
        if(s.id === server_id) {
          server = s;
          return;
        }
      })
      if(debug) console.log(`[STORE] getServer(${server_id}) => ${server}`);
      return server;
    },
    getDTLocale: (state) => () => {
      return 'de';
    },
  },
  mutations: {
    setRoute(state, route) {
      if(debug) console.log(`[STORE] setRoute()`);
      state.route = route;
    },
    setRoles(state, roles) {
      if(debug) console.log(`[STORE] setRoles(${roles})`);
      state.roles = roles;
    },
    actionLogout(state) {
      if(debug) console.log(`[STORE] actionLogout()`);
      state.loginState = SessionLoginState.NOT_LOGGED_IN;
      state.accountId = null;
      state.accountName = null;
    },
    setLoginState(state, loginState) {
      if(debug) console.log(`[STORE] setLoginState(${loginState})`);
      state.loginState = loginState;
    },
    setAccountName(state, accountName) {
      if(debug) console.log(`[STORE] setAccountName(${accountName})`);
      state.accountName = accountName;
    },
    setAccountId(state, accountId) {
      if(debug) console.log(`[STORE] setAccountId(${accountId})`);
      state.accountId = accountId;
    },
    setPersonaName(state, personaName) {
      if(debug) console.log(`[STORE] setPersonaName(${personaName})`);
      state.personaName = personaName;
    },
    setPersonaAvatar(state, personaAvatar) {
      if(debug) console.log(`[STORE] setPersonaAvatar(${personaAvatar})`);
      state.personaAvatar = personaAvatar;
    },
    setUILanguage(state, uiLanguage) {
      if(debug) console.log(`[STORE] setUILanguage(${uiLanguage})`);
      state.uiLanguage  = uiLanguage; // Set for local storage
      i18n.locale       = uiLanguage; // Set for i18n
    },
    setSubscriberStatus(state, status) {
      if(debug) console.log(`[STORE] setUISubscriberStatus(${status})`);
      state.uiSubscriber = status;
    },
    clearIterableResources(state) {
      if(debug) console.log(`[STORE] clearIterableResources()`);
      state.roles = [];
      state.teams = [];
      state.servers = [];
      state.memberships = [];
      state.resources = {};
    },
    addServer(state, server) {
      if(debug) console.log(`[STORE] addServer(${server})`);
      state.servers.push(server);
      if(server.team_id) {
        state.resources[server.team_id].push(server);
      }
    },
    removeServer(state, server) {
      if(debug) console.log(`[STORE] removeServer(${server})`);
      let index = state.servers.indexOf(server);
      if(index >= 0) state.servers.splice(index, 1);
      if(server.team_id) {
        let index = state.resources[server.team_id].indexOf(server);
        if(index >= 0) state.resources[server.team_id].splice(index, 1);
      }
    },
    setServer(state, {server_id, data}) {
      let serverData = null;
      let serverIndex = null;
      state.servers.forEach((s, i) => {
        if(s.id === server_id) {
          serverData = s;
          serverIndex = i;
          return;
        }
      })
      if(debug) console.log(`[STORE] setServer(${server_id}) : ${serverData} > ${data}`);
      if(serverIndex === null) throw new Error(`${server_id} not in store`);
      state.servers[serverIndex] = {
        ...serverData,
        ...data
      };
    },
    addMembership(state, membership) {
      if(debug) console.log(`[STORE] addMembership(${membership})`);
      state.memberships.push(membership);
    },
    removeMembership(state, membership) {
      if(debug) console.log(`[STORE] removeMembership(${membership})`);
      let index = state.memberships.indexOf(membership);
      if(index >= 0) state.memberships.splice(index, 1);
    },
    addTeam(state, team) {
      if(debug) console.log(`[STORE] addTeam(${team})`);
      state.teams.push(team);
      state.resources[team.id] = [];
    },
    removeTeam(state, team) {
      if(debug) console.log(`[STORE] removeTeam(${team})`);
      let index = state.teams.indexOf(team);
      if(index >= 0) state.teams.splice(index, 1);
      delete state.resources[team.id];
    }
  }
})

export default store

