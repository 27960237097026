// !!! THIS FILE HAS BEEN AUTOMATICALLY GENERATED BASED ON CFSDK SOURCE FILES; DO NOT EDIT !!!
export const AccountAuthProvider = {NATIVE: 0,STEAM: 1};
export const AccountStatus = {NOT_VERIFIED: 0,VERIFIED: 1,TERMINATED: 2,IN_SETUP: 3,DISABLED: 4,BANNED: 5};
export const BanCategory = {OTHER: 0,CHEATING: 1,GLITCHING: 2,FRAUD: 3,INSULTING: 4,RACISM: 5,RULE_VIOLATION: 6};
export const BanType = {CFTOOLS_ID: 0,IPV4: 1,IPV4_RANGE: 2,IPV6: 3,IP: 4};
export const BillingProvider = {NONE: 0,PAYPAL: 1,STRIPE: 2};
export const BucketType = {GENERIC: 0,NOTES: 1,WHITELIST: 2,NAME_FILTER: 3,CHAT_FILTER: 4,RESERVED_SLOT: 5,QUEUE_PRIORITY: 6};
export const ClientAuthorization = {General: 0,WorkerHost: 1,InternalHost: 2,Internal: 3};
export const ClientClassification = {InternalService: 0,API: 1,Webinterface: 2,OmegaNode: 3};
export const ClientPlatform = {Deprecated: 0,Omega: 1};
export const ClientRealm = {General: 0,Internal: 1,Omega: 2};
export const ClientState = {Created: 0,Decommissioned: 1,Offline: 2,Dead: 3,Active: 4,Disabled: 5};
export const ContainerProvider = {Plain: 0,Docker: 1,Kubernetes: 2,LXC: 3};
export const DateTimeType = {TIMESTAMP: 0,ISO8601: 1};
export const EMailVerification = {MISSING: 0,PENDING: 1,VERIFIED: 2};
export const Game = {GENERIC: 0,DAYZ: 1,ARMA2: 2,ARMA3: 3,RUST: 4,DAYZ_EXP: 5};
export const GameServerQueryError = {NONE: 0,GENERIC: 1,TIMEOUT: 2,SPOOFED: 3,UNSUPORTED_VERSION: 4,CONNECTION_FAILED: 5};
export const GameSessionState = {CREATED: 0,CONCLUDED: 1,ARCHIVED: 2,ERROR: 3,CONCLUDING: 4,QUEUED: 10,PROCESSING: 11,POPULATED: 12,GATHERING_PERSONA: 20,ACTIVE: 30};
export const HostAlertStates = {Created: 0,InProgress: 1,Resolved: 2};
export const HostAuthorization = {Unauthorized: 0,ExternalHost: 1,InternalHost: 2,CoreInfrastructure: 3};
export const HostTypes = {BareMetal: 0,Containerized: 1,Virtualized: 2};
export const IdentityType = {NONE: -1,STEAMID: 0,BATTLEYE_GUID: 1};
export const InvoiceType = {SIMPLE: 0,SUBSCRIPTION: 1,MIXED: 2};
export const LabelFilterAttribute = {SESSION_COUNT: 0};
export const LabelFilterCondition = {MATCH: 0,LESS_THEN: 1,GREATER_THEN: 2};
export const LogCategory = {AUDIT: 0,RCON: 1,GAME: 2,SERVER: 3};
export const LogLevel = {INFO: 0,WARNING: 1,ERROR: 2,CRITICAL: 3};
export const NotificationCategory = {GENERAL: 0,ADVERTISEMENT: 1,ALERT: 2,IMPORTANT: 3};
export const NotificationObjectStatus = {CREATED: 0,SCHEDULED: 1,FIRED: 2,COMPLETED: 3,READ: 4};
export const OmegaAction = {HELP: 0,RAW: 1,MESSAGE_PRIVATE: 10,MESSAGE_PUBLIC: 11,SERVER_LOCK: 20,SERVER_UNLOCK: 21,SERVER_RESTART: 22,SERVER_KICK: 23,SERVER_WIPE: 24,WHITELIST_PAUSE: 31,WHITELIST_RESUME: 32,GAME_KILL: 90};
export const PacketCipherMode = {NONE: 0,ECB: 1,CBC: 2};
export const PacketCompression = {NONE: 0,LZMA: 1,BZ2: 2};
export const PacketEncryptionMode = {DEFAULT: 0,LAX: 1,STRICT: 2};
export const PacketType = {DUMMY: 0,KEYEXCHANGE: 1,PING: 2,AUTH: 3,CMD: 4,PSCMD: 5,EVENT: 6,RPC: 7};
export const PayPalBillingAgreementStatus = {APPROVAL_PENDING: 0,APPROVED: 1,ACTIVE: 2,SUSPENDED: 3,CANCELLED: 4,EXPIRED: 5};
export const PersonaType = {GENERIC: 0,STEAM: 1};
export const ProductBillingCycle = {ONCE: 0,MONTHLY: 1,YEARLY: 2,DAILY: 3,QUARTERLY: 4};
export const ProductCategory = {DEFAULT: 0,FREE: 1,BASIC: 2,PRO: 3,EXTENDED: 4};
export const ProductLicense = {NONE: 0,SUBSCRIPTION: 1,PERPETUAL: 2};
export const ProductRealm = {INTERNAL: 0,OMEGA_DEV: 1,OMEGA: 2,ACCOUNT: 3};
export const ProductStatus = {INACTIVE: 0,ACTIVE: 1};
export const ServerSetupStep = {CREATED: 0,GAME: 1,HOST: 2,NICKNAME: 3,TEAM: 4,BUCKETS: 5};
export const ServerState = {IN_SETUP: 0,ACTIVE: 1,SUSPENDED: 2,DEACTIVATED: 3,MARKED_FOR_DELETION: 4};
export const SessionLoginState = {NOT_LOGGED_IN: 0,LOGGED_IN: 1,VERIFY_2FA: 2,VERIFY_LEGAL: 3,PASSWORD_PROMPT: 4,IN_SETUP: 5};
export const SteamWorkshopItemState = {PUBLIC: 1,PRIVATE: 2,DELETED: 3};
export const StreamEvent = {NONE: 0,CHAT: 1,JOIN: 2,LEAVE: 3,LOADED: 4,TICK: 5,UPDATE: 6,KILL: 90};
export const SubscriptionStatus = {INACTIVE: 0,ACTIVE: 1,ARCHIVED: 2,EXPIRED: 3,CANCELLED: 4,PAYMENT_PENDING: 5,CONFIRMATION_PENDING: 6};
export const TicketCategory = {GENERIC: 0,BILLING: 1,PRODUCT: 2,TECHNICAL: 3,ISSUE: 4,ACCOUNT: 5,API: 6};
export const TicketPriority = {NONE: 0,LOW: 1,MEDIUM: 2,HIGH: 3,CRITICAL: 4};
export const TicketSection = {TECHNICAL: 1,BILLING: 2,OTHER: 3};
export const TicketStatus = {WAITING_FOR_TRIAGE: 0,WAITING_FOR_SUPPORT: 1,WAITING_FOR_USER: 2,CLOSED: 3};
export const TriggerAttributes = {NONE: 0,MESSAGE: 1,CFTOOLS_ID: 2,PLAYER_NAME: 3,SESSION_TIME: 10,IS_ADMIN: 90,HOUR: 100,MINUTE: 101,POSITION: 200,SERVER_LOCK_STATE: 300,SERVER_QUEUE: 400,SERVER_PLAYERS: 401,SERVER_GAMETIME: 402};
export const TriggerCondition = {NONE: 0,MATCH: 1,LESS_THEN: 2,GREATER_THEN: 3,GEO_POSITION: 4,TRUE: 5,FALSE: 6};
export const TwoFAStatus = {MISSING: 0,PENDING: 1,VERIFIED: 2};
export const Universe = {INTERNAL: 0,CFTOOLS: 1,STEAM: 2};
export const WebHookDeliveryState = {QUEUED: 0,SCHEDULED: 1,SENT: 2,FAILED: 3};
export const WebHookFlavor = {CFTOOLS: 0,DISCORD: 1};
export const WebHookPeerTrustState = {UNDECIDED: 0,NO_CONCERNS: 1,SSL_ERROR: 2,NOT_SECURE: 3};
export const WebHookPurpose = {TESTING: 0,INTERNAL: 1,INTERNAL_ALERTS: 2,OMEGA: 10,SERVER: 20,BANLIST: 30,GSM: 40};
export const WebHookState = {CREATED: 0,VERIFYING: 1,VERIFY_ERROR: 2,VERIFIED: 3,PAUSED: 4,ERROR: 5};
export const WorkerState = {STARTING: 0,CONNECTING: 1,CONNECTED: 2,DISCONNECTED: 3,ERROR: 4};
