import store from '@/state/store'
import {AccountStatus, SessionLoginState} from '@/enums'

export default [
  {
    path: '',
    name: 'index',
    meta: {
      login_required: false,
      beforeResolve(routeTo, routeFrom, next) {
        next({name: 'dashboard'});
      },
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/router/view/dashboard/index'),
    meta: {
      login_required: true
    },
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('@/router/view/account/index'),
    meta: {
      login_required: true
    },
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('@/router/view/support/index'),
    meta: {
      login_required: true
    },
  },
  {
    path: '/ban-manager/dashboard',
    name: 'banmanager_dashboard',
    component: () => import('@/router/view/banmanager/dashboard'),
    meta: {
      login_required: true
    },
  },
  {
    path: '/ban-manager/list',
    name: 'banmanager_list',
    component: () => import('@/router/view/banmanager/list'),
    meta: {
      login_required: true
    },
  },
  {
    path: '/ban-manager/:banlist_id',
    name: 'banmanager_view',
    component: () => import('@/router/view/banmanager/view'),
    meta: {
      login_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        next();
      }
    }
  },
  {
    path: '/support/:ticket_id',
    name: 'support_read',
    component: () => import('@/router/view/support/read'),
    meta: {
      login_required: true
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      auth_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/acsrf-token', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          }
        })
        .then(data => {
          console.log(data);
          /* Actual request */
          let payload = {
            acsrf_token: data.acsrf_token
          };
          fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/logout', {
            method: 'POST',
            body: JSON.stringify(payload),
            credentials: 'include'
          })
          .then(response => {
            if(response.ok){
              return response.json();
            }
          })
          .then(data => {
            console.log('Logging out...');
            store.commit('actionLogout');
            location.reload();
          })
          .catch(error => {
            console.log(error);
          });
          /* --- */
        })
        .catch(error => {
          console.log(error);
        });
      },
    },
  },
  {
    path: '*',
    name: 'not-found',
    component: () => import('@/router/view/errors/404'),
    meta: {
      auth_required: false
    }
  }
]
